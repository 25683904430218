const addDays = (date, days) => {
  const modifiedDate = new Date(Number(date));
  modifiedDate.setDate(date.getDate() + days);
  const formattedDate =
    modifiedDate.getMonth() +
    1 +
    '/' +
    modifiedDate.getDate() +
    '/' +
    modifiedDate.getFullYear();
  return formattedDate;
};

export default addDays;
