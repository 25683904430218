import {
  Container,
  Row,
  Col,
  CardGroup,
  Card,
  Tab,
  Nav,
  Button,
} from 'react-bootstrap';
import calcShippingWindow from '../../utils/calcShippingWindow';
import './OrderInfoHeader.css';

const OrderInfoHeader = (props) => {
  const accessLevel = props.orderInfo.accessLevel;
  const { beginWindowDate, endWindowDate } = calcShippingWindow(
    props.orderInfo.expectedShipDate
  );
  let studentRosterLinkDefault = "https://rostersc.signaturechampions.com"
  let currentLocation = window.location.href;

  const studentRosterLink = () => {
    if(currentLocation.includes("localhost") || currentLocation.includes("staging")){
      studentRosterLinkDefault = "https://staging.d2woldm9yjzho6.amplifyapp.com"
    }
    return studentRosterLinkDefault
  }

  return (
    <>
      <Container>
        {accessLevel === 'Coach' ? (
          <Tab.Container defaultActiveKey="first" transition={false}>
            <Nav fill justify variant="tabs">
              <Nav.Item>
                <Nav.Link eventKey="first">Order Info</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second">Addresses</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="third">Order Link</Nav.Link>
              </Nav.Item>
            </Nav>
            <Row>
              <Col sm>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <CardGroup>
                      <Card>
                        <Card.Header>
                          Order #{props.orderInfo.orderId}
                        </Card.Header>
                        <Card.Body>
                          <h3>{props.orderInfo.organizationName}</h3>
                          <h6>{props.orderInfo.sportAndYear}</h6>
                          <p>Order Status: {props.orderInfo.status}</p>
                        </Card.Body>
                      </Card>
                      {props.orderInfo.expectedShipDate !== 'TBD' && (
                        <Card>
                          <Card.Header>
                            Estimated Shipping Date Window
                          </Card.Header>
                          <Card.Body>
                            {props.orderInfo.expectedShipDate === 'TBD' ? (
                              <>TBD</>
                            ) : (
                              <>
                                {beginWindowDate} &mdash; {endWindowDate}
                              </>
                            )}
                            <br></br>
                            <br></br>
                            If you are planning an event, please contact us for
                            a more accurate date.
                          </Card.Body>
                        </Card>
                      )}
                    </CardGroup>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <CardGroup>
                      <Card>
                        <Card.Header className="card-header">
                          Shipping Address
                        </Card.Header>
                        <Card.Body>
                          {props.orderInfo.shippingAddress.line1 ? (
                            <div>
                              {props.orderInfo.shippingAddress.line1}
                              <br></br>
                              {props.orderInfo.shippingAddress.line2 && (
                                <>
                                  {props.orderInfo.shippingAddress.line2}
                                  <br></br>
                                </>
                              )}
                              {props.orderInfo.shippingAddress.city},{' '}
                              {props.orderInfo.shippingAddress.state}{' '}
                              {props.orderInfo.shippingAddress.postalCode}
                              <br></br>
                              {props.orderInfo.shippingAddress.country}
                            </div>
                          ) : (
                            'No Shipping Address Available'
                          )}
                        </Card.Body>
                      </Card>

                      <Card>
                        <Card.Header className="card-header">
                          Billing Address
                        </Card.Header>
                        <Card.Body>
                          {props.orderInfo.billingAddress.line1 ? (
                            <div>
                              {props.orderInfo.billingAddress.line1}
                              <br></br>
                              {props.orderInfo.billingAddress.line2 && (
                                <>
                                  {props.orderInfo.billingAddress.line2}
                                  <br></br>
                                </>
                              )}
                              {props.orderInfo.billingAddress.city},{' '}
                              {props.orderInfo.billingAddress.state}{' '}
                              {props.orderInfo.billingAddress.postalCode}
                              <br></br>
                              {props.orderInfo.billingAddress.country}
                            </div>
                          ) : (
                            'No Billing Address Available'
                          )}
                        </Card.Body>
                      </Card>
                    </CardGroup>
                  </Tab.Pane>
                  <Tab.Pane eventKey="third">
                    <Card>
                      <Card.Header>Team Ordering Link</Card.Header>
                      <Card.Body>
                        {props.orderInfo.paymentType !== 'Individual Pay' && (
                          <>
                            <p>
                              Share this link if you plan to have team members
                              enter their own orders.
                            </p>
                            <a
                              href={`${studentRosterLink()}/${props.orderInfo.studentAccessKey}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {studentRosterLink()}
                              {`/${props.orderInfo.studentAccessKey}`}
                            </a>
                            <br></br>
                            <Button
                              className="mt-3"
                              id="copy"
                              variant="primary"
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  `${studentRosterLink()}/${props.orderInfo.studentAccessKey}`
                                );
                                document.getElementById('copy').innerText =
                                  'Copied!';
                              }}
                            >
                              Copy To Clipboard
                            </Button>
                          </>
                        )}
                        {props.orderInfo.paymentType === 'Individual Pay' && (
                          <>
                            <a
                              href={`https://store.signaturechampions.com/collections/${props.orderInfo.orderId}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              https://store.signaturechampions.com/collections/
                              {props.orderInfo.orderId}
                            </a>
                            <br></br>
                            <Button
                              className="mt-3"
                              id="copy"
                              variant="primary"
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  `https://store.signaturechampions.com/collections/${props.orderInfo.orderId}`
                                );
                                document.getElementById('copy').innerText =
                                  'Copied!';
                              }}
                            >
                              Copy To Clipboard
                            </Button>
                          </>
                        )}
                      </Card.Body>
                    </Card>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        ) : (
          <Card className="mb-2">
            <Card.Header>Order #{props.orderInfo.orderId}</Card.Header>
            <Card.Body>
              <h3>{props.orderInfo.organizationName}</h3>
              <h6>{props.orderInfo.sportAndYear}</h6>
            </Card.Body>
          </Card>
        )}
      </Container>
    </>
  );
};

export default OrderInfoHeader;
