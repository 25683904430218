import axios from 'axios';
import React, { useState, useEffect } from 'react';

const baseUrl = `https://${process.env.REACT_APP_API_SERVER}/api/Rosters/`;

const addRosterLine = (rosterKey, formData) => axios.post(`${baseUrl}${rosterKey}/Lines`, formData);

const deleteRosterLine = (rosterKey, lineKey) => axios.delete(`${baseUrl}${rosterKey}/Lines/${lineKey}`);

const updateRosterLine = (rosterKey, lineKey, formData) =>
  axios.put(`${baseUrl}${rosterKey}/Lines/${lineKey}`, formData);

export {
  addRosterLine,
  deleteRosterLine,
  updateRosterLine,
};