import React, { createContext, useEffect, useState } from 'react';
import getUser from '../utils/getUser';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const pathPieces = window.location.pathname.split('/');
    const [user, setUser] = useState(null);

    useEffect(() => {
        if (pathPieces[2] === 'internal') {
            if (pathPieces[3]) {
                const fetchUser = async () => {
                    const userId = pathPieces[3];
                    const thisUser = await getUser(userId);
                    if (thisUser !== false) {
                        setUser(thisUser);
                    } else {
                        console.log("NO USER FOUND");
                    }
                };

                fetchUser();
            }
        }
    }, []);

    return (
        <UserContext.Provider value={{ user }}>
            {children}
        </UserContext.Provider>
    );
}