import axios from 'axios';

const downloadRoster = async (rosterKey, rosterType) => {
  try {
    let baseUrl = `https://${process.env.REACT_APP_API_SERVER}/api/Rosters/`;
    await axios({
      url: `${baseUrl}${rosterKey}/${rosterType}`,
      method: 'GET',
      responseType: 'blob',
    }).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'roster.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  } catch (e) {
    console.log(e);
    return false;
  }
};

export default downloadRoster;
