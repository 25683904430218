import axios from 'axios';

const getRoster = async (rosterKey) => {
  try {
    const url = `https://${process.env.REACT_APP_API_SERVER}/api/Rosters/${rosterKey}`;
    //console.log(url);
    const res = await axios.get(url);
    if (res.data) {
      //console.log(res);
      // Fix cases where the variation metal or size has changed since the roster line was created
      let d = res.data;
      for (let i = 0; i < d.lines.length; i++) {
        let lineVariationId = d.lines[i].variationId
        let matchFound = false;
        for (let ii = 0; ii < d.products.length && matchFound === false; ii++) {
          for (let iii = 0; iii < d.products[ii].variations.length && matchFound === false; iii++) {
            if (d.products[ii].variations[iii].variationId === lineVariationId) {
              d.lines[i].variation = d.products[ii].variations[iii].description;
              matchFound = true;
            }
          }
        }
      }
      return res.data;
    } else {
      console.error('EMPTY ROSTER RETURNED', rosterKey);
      return false;
    }
  } catch (e) {
    console.error('ERROR RETRIEVING ROSTER', rosterKey);
    console.error(e);
    return false;
  }
};

export default getRoster;
