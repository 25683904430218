import PlayerCardBody from './PlayerCardBody';
import { Card, Button } from 'react-bootstrap';
import { useState } from 'react';

const PlayerCards = ({
  sortedPlayers,
  product,
  orderInfo,
  editPlayer,
  editPlayers,
  deleteCard,
  rosterComplete,
  individualSelection,
  multipleSelection,
  selectedPlayers,
  setSelectedPlayers,
  playerSelectCount,
  setPlayerSelectCount,
  incrementPlayerCount,
  decrementPlayerCount,
  internalView,
}) => {
  function filterPlayers(players, product) {
    if (product !== '')
      return players.filter(
        (player) => player.masterOrderItemId === product.masterOrderItemId
      );
    else {
      return players.filter(
        (player) =>
          player.masterOrderItemId === null || player.masterOrderItemId === ''
      );
    }
  }
  const [productTitle, setProductTitle] = useState(product.title);
  const [productType] = useState(product.productType);
  
  if(productTitle === null || productTitle === '' || productTitle === undefined ){
    setProductTitle("NoProduct");
  }
  const chooseBackgroundColor = (metal) => {
    if (metal.includes('Black') || metal.includes('Gunmetal')) {
      return 'black';
    } else if (metal.includes('Silver') || metal.includes('Steel')) {
      return 'silver';
    } else if (metal.includes('Rose')) {
      return 'pink';
    } else if (metal.includes('Gold')) {
      return 'gold';
    } else {
      return 'black';
    }
  };

  const chooseTextColor = (metal) => {
    if (metal.includes('Black') || metal.includes('Gunmetal')) {
      return 'white';
    } else if (metal.includes('Silver') || metal.includes('Steel')) {
      return 'black';
    } else if (metal.includes('Rose')) {
      return 'black';
    } else if (metal.includes('Gold')) {
      return 'black';
    } else {
      return 'white';
    }
  };

    return (
    <>
      {product.variations ? (
        product.variations.map((variant) => (
          <Card key={variant.description}>
            <Card.Header
              ref={(node) => {
                if (node) {
                  const bgColor = chooseBackgroundColor(node.innerText);
                  const textColor = chooseTextColor(node.innerText);
                  node.style.setProperty(
                    'background-color',
                    bgColor,
                    'important'
                  );
                  node.style.setProperty('color', textColor, 'important');
                }
              }}
            >
              {variant.description}
            </Card.Header>
            {filterPlayers(sortedPlayers, product).filter(
              (player) =>  player.variationId === variant.variationId || player.variation === variant.description
            ).length === 0 ? (
              <Card bg="light">
                <Card.Body>
                  <Card.Title>No orders.</Card.Title>
                </Card.Body>
              </Card>
            ) : (
              filterPlayers(sortedPlayers, product)
                .filter((player) => player.variationId === variant.variationId || player.variation === variant.description)
                .map((player, idx) => (
                  <PlayerCardBody
                    deleteCard={deleteCard}
                    editPlayer={editPlayer}
                    player={player}
                    count={idx + 1}
                    key={idx}
                    rosterComplete={rosterComplete}
                    orderInfo={orderInfo}
                    individualSelection={individualSelection}
                    selectedPlayers = {selectedPlayers}
                    setSelectedPlayers = {setSelectedPlayers}
                    productSelected = {true}
                    productTitle = {productTitle}
                    productType = {productType}
                    productVariation = {variant.description}
                    internalView = {internalView}
                  />
                ))
            )}
          </Card>
        ))
      ) : (
        <Card>
          <Card.Header>
            <p>Please choose a product and options for each of these orders.<br/><br/>To choose a product for multiple orders, please click on the orders you wish to edit and click Edit Selected Records at the bottom of this section</p>
          </Card.Header>
          {filterPlayers(sortedPlayers, product).filter(
            (player) => player.masterOrderItemId === null
          ).length === 0 ? (
            <Card bg="light">
              <Card.Body>
                <Card.Title>No orders.</Card.Title>
              </Card.Body>
            </Card>
          ) : (
            filterPlayers(sortedPlayers, product)
              .filter((player) => player.masterOrderItemId === null)
              .map((player, idx) => (
                <>
                <PlayerCardBody
                  deleteCard={deleteCard}
                  editPlayer={editPlayer}
                  player={player}
                  count={idx + 1}
                  key={idx}
                  rosterComplete={rosterComplete}
                  individualSelection = {individualSelection}
                  playerSelectCount = {playerSelectCount}
                  setPlayerSelectCount = {setPlayerSelectCount}
                  incrementPlayerCount = {incrementPlayerCount}
                  decrementPlayerCount = {decrementPlayerCount}
                  selectedPlayers = {selectedPlayers}
                  setSelectedPlayers = {setSelectedPlayers}
                  productSelected = {false}
                  productTitle = {productTitle}
                  productType = {productType}
                  internalView = {internalView}
                />
                </>
              ))
          )}
          {playerSelectCount > 0 && rosterComplete === false &&(
          <Button id = "multiUpdate" name = "multiUpdate" onClick = {()=> {
            multipleSelection();
            editPlayers(selectedPlayers);
          }}>Edit Selected Records</Button>)}
        </Card>
      )}
    </>
  );
};

export default PlayerCards;
