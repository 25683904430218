import addDays from './addDays';

const calcShippingWindow = (date) => {
  const estShipDate = new Date(date);
  const beginWindowDate = addDays(estShipDate, -7);
  const endWindowDate = addDays(estShipDate, 10);
  return { beginWindowDate, endWindowDate };
};

export default calcShippingWindow;
