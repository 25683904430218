import React, { useState, useEffect } from 'react';
import { Navbar, Spinner, Container } from 'react-bootstrap';
import logo from '../../assets/images/signature-logo.svg';
import RingSelectionView from '../../components/RingSelectionView/RingSelectionView';
import RosterPage from '../../pages/RosterPage/RosterPage';
import getRoster from '../../utils/getRoster';
import getUser from '../../utils/getUser';
import OrderInfoHeader from '../../components/OrderInfoHeader/OrderInfoHeader';
import downloadRoster from '../../utils/downloadRoster';
import './OrderPage.css';

const OrderPage = () => {
  //Coach Key 30047
  //const rosterId = "8b998b07-22d8-4b4a-bbcf-f4bf6c14ed11";
  //Coach Key 30048
  //const rosterId = "1d66c297-a727-4ce4-91f3-90a562c6f9b7";
  //Student Key 30048
  //const rosterId = "94057f77-69b9-4720-99cf-feed40f6faf2";
  const pathPieces = window.location.pathname.split('/');
  const rosterKey = pathPieces[1];
  const [roster, setRoster] = useState({});
  const [loading, setLoading] = useState(true);
  const [orderInfo, setOrderInfo] = useState({});
  const [isError, setIsError] = useState(false);
  const [isCoach, setIsCoach] = useState(false);
  const [isInternalView, setIsInternalView] = useState(false);
  const [user, setUser] = useState({});
  const [validUser, setValidUser] = useState(false);
  const authorizedUsers = ['Admin','SalesManager', 'Verifier'];

  useEffect(() => {
    if (pathPieces[2] === 'PlantRosterFile') {
      downloadRoster(rosterKey, 'PlantRosterFile');
    }
  }, []);

  useEffect(() => {
    if(pathPieces[2] === 'internal'){
      setIsInternalView(true);
      console.log("INTERNAL VIEW");
      if(pathPieces[3]){
        const fetchUser =  async () => {
          const userId = pathPieces[3];
          console.log(userId);
          const thisUser = await getUser(userId);
          if(thisUser !== false){
          setUser(thisUser);
          
          console.log(JSON.stringify(authorizedUsers));;
          //console.log(JSON.stringify(user.roles));
          const isValid = authorizedUsers.some(r => thisUser.roles.includes(r));
          setValidUser(isValid);
          console.log("IS VALID: " + isValid);
        }
          else{
            setValidUser(false);
            console.log("NO USER FOUND");
          }
          

        };
        fetchUser();
      }
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const newRoster = await getRoster(rosterKey);
      setRoster(newRoster);
    };
    fetchData();
  }, [rosterKey]);

  useEffect(() => {
    if (roster && roster.lines) {   
      setLoading(false);
      setOrderInfo(roster);
      setIsCoach(roster.accessLevel === 'Coach');
      console.log(roster);
    }
    if (roster === false) {
      setLoading(false);
      setIsError(true);
    }
  }, [roster]);

  return (
    <>
      {loading && (
        <div className="loader">
          <Spinner animation="border" />
        </div>
      )}
      {console.log("INTERNAL VIEW: " + isInternalView)}
      {isError && <div className="loader">Something went wrong.</div>}
      {(isCoach && isInternalView && !validUser ) ? (
              <><div>You are not authorized to view this page</div></>
            ):(<>
      {!loading && !isError && (
        <>
          <Navbar>
            <img src={logo} className="logo" alt="signature champions logo" />
          </Navbar>
          <div className="contentBody">
            {orderInfo && <OrderInfoHeader orderInfo={orderInfo} />}
            <Container>
              {!isCoach && roster.rosterComplete !== true && (
                <>
                  <div id="mainModal" className="contentBody">
                    <RingSelectionView
                      rosterKey={rosterKey}
                      roster={roster}
                      playerData={''}
                      individualSelection={true}
                      internalView={false}
                    />
                  </div>
                </>
              )}
              {!isCoach && roster.rosterComplete === true && (
                <div style={{ textAlign: 'center' }}>Roster is closed.</div>
              )}
            </Container>
            {isCoach && isInternalView && validUser &&<RosterPage roster={roster} rosterKey={rosterKey} orderInfo={orderInfo} internalView={isInternalView}/>}
            
            {isCoach && !isInternalView && <RosterPage roster={roster} rosterKey={rosterKey} orderInfo={orderInfo} internalView={isInternalView}/>}
          </div>
        </>
        )
      }</>
  )
}</>);};

export default OrderPage;
