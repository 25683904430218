import axios from 'axios';

const getStates = async (countryCode) => {
  try {
    let url = `https://${process.env.REACT_APP_API_SERVER}/api/Rosters/States`;
    if (countryCode != null && countryCode != '') {
      url = url + `?countryCode=${countryCode}`;
    }

    const res = await axios.get(url);
    if (res.data) {
      return res.data;
    } else {
      console.error('EMPTY STATE');
      return [];
    }
  } catch (e) {
    console.error('ERROR RETRIEVING STATE');
    console.error(e);
    return [];
  }
};

export default getStates;
