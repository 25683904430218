import axios from 'axios';

const getUser = async (userId) => {
  console.log("USER ID: " + userId)
  try {
    const url = `https://${process.env.REACT_APP_API_SERVER}/api/Rosters/Users/${userId}`;
    //console.log(url);
    const res = await axios.get(url);
    if (res.data) {
      return res.data;
    } else {
      console.error('USER NOT FOUND', userId);
      return false;
    }
  } catch (e) {
    console.error('ERROR RETRIEVING USER', userId);
    console.error(e);
    return false;
  }
};

export default getUser;
