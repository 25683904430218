import axios from 'axios';
import React, { useState, useEffect } from 'react';

const url = `https://hook.integromat.com/83ni62ph8ih8996iq3pjbj3a1ydwspc1`;

const baseUrl = `https://${process.env.REACT_APP_API_SERVER}/api/Rosters/`;

const sendRosterCompleteNotification = async (rosterData) => {
  await axios.post(url, rosterData);
};

const updateRosterCompleteFlag = async (rosterData) => {
  const rosterKey = rosterData.coachAccessKey;
  const rosterId = rosterData.id;
  await axios.put(`${baseUrl}${rosterKey}`, {
    id: `${rosterId}`,
    rosterComplete: true,
  });
};

export { sendRosterCompleteNotification, updateRosterCompleteFlag };
