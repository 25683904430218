import axios from 'axios';

const getCountries = async (countryCode) => {
  try {
    let url = `https://${process.env.REACT_APP_API_SERVER}/api/Rosters/Countries`;
    const res = await axios.get(url);
    if (res.data) {
      return res.data;
    } else {
      console.error('EMPTY COUNTRY');
      return [];
    }
  } catch (e) {
    console.error('ERROR RETRIEVING COUNTRY');
    console.error(e);
    return [];
  }
};

export default getCountries;