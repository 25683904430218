//import neccessary components
import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import {
  Button,
  Card,
  Accordion,
  Modal,
  Container,
  Row,
  Col,
  Image,
  Spinner,
} from 'react-bootstrap';
import RingSelectionView from '../RingSelectionView/RingSelectionView';
import './PlayerCard.css';
import PlayerCards from './PlayerCardParts/PlayerCards';
import { deleteRosterLine } from '../../utils/rosterLineCalls';
import {
  sendRosterCompleteNotification,
  updateRosterCompleteFlag,
} from '../../utils/rosterCompleteCalls';
import downloadRoster from '../../utils/downloadRoster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

const PlayerCard = ({
  players,
  products,
  roster,
  orderInfo,
  rosterKey,
  internalView,
}) => {
  const [show, setShow] = useState(false);
  const [player, setPlayer] = useState('');
  const [editCancelled, setEditCancelled] = useState(false);
  const [sortedPlayers, setSortedPlayers] = useState(sortLines(players));
  const [showRosterCompleteModal, setShowRosterCompleteModal] = useState(false);
  const [allDoneVideoModal, setAllDoneVideoModal] = useState(false);
  const [downloadingRoster, setDownloadingRoster] = useState(false);
  const [closeRosterLoading, setCloseRosterLoading] = useState(false);
  const [rosterComplete, setRosterComplete] = useState(roster.rosterComplete);
  const [individualSelection, setIndividualSelection] = useState(true);
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [playerSelectCount, setPlayerSelectCount] = useState(0);

  const incrementPlayerCount = () => {
    setPlayerSelectCount((playerSelectCount) => playerSelectCount + 1);
  };

  const decrementPlayerCount = () => {
    setPlayerSelectCount((playerSelectCount) => playerSelectCount - 1);
  };
  const enableIndividualSelection = () => setIndividualSelection(true);
  const enableMultipleSelection = () => setIndividualSelection(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const cancelEdit = () => {
    setEditCancelled(true);
    setShow(false);
  };

  const enablePlayerEdit = (playerData) => {
    handleShow();
    setPlayer(playerData);
  };

  const enablePlayersEdit = (playersData) => {
    handleShow();
    playersData.map((playerData) => setPlayer(playerData));
  };

  const enablePlayerAdd = (product) => {
    console.log('ENABLE PLAYER ADD');
    console.log(product);
    if (!product) {
      console.log('Product is empty');
      if (roster.products.length === 1) {
        product = roster.products[0];
      }
    }
    handleShow();
    enableIndividualSelection();
    console.log(individualSelection);
    let emptyPlayer = {
      id: '',
      rosterId: roster.id,
      masterOrderItemId: product ? product.masterOrderItemId : '',
      variation: '',
      variationId: '',
      studentName: '',
      studentEmail: '',
      studentPhone: '',
      addressLine1: '',
      addressLine2: null,
      city: '',
      state: '',
      postalCode: '',
      customizations: [],
      editable: true,
      package: null,
      optionalEngraving:
        product != null &&
        product?.productType != 'Championship Ring' &&
        product?.productType != 'Fashion Ring'
          ? false
          : null,
    };
    setPlayer(emptyPlayer);
  };

  const updatePlayer = (playerData) => {
    // Don't have the form force a roster line ID onto a suborder
    if (
      playerData.id.startsWith('SubOrder') &&
      playerData.rosterId !== 'SubOrder'
    ) {
      playerData.rosterId = 'SubOrder';
    }
    let newPlayers = sortedPlayers.map((player) =>
      player.id === playerData.id ? playerData : player
    );
    setSortedPlayers(sortLines(newPlayers));
  };

  const addPlayer = (playerData) => {
    let newPlayers = sortedPlayers;
    newPlayers.push(playerData);
    setSortedPlayers(sortLines(newPlayers));
  };

  function selectAllPlayers(players, selectAll) {
    let playerChecks = document.getElementsByClassName('checkButtons');
    let productRows = document.getElementsByClassName('NoProduct');
    let playerLength = playerChecks.length;
    let rowColor;
    let textColor;
    if (selectAll) {
      rowColor = '#606666';
      textColor = 'white';
    } else {
      rowColor = '#f8f9fa';
      textColor = 'black';
    }

    //productRows.style.backgroundColor = rowColor;
    for (let i = 0; i < playerLength; i++) {
      playerChecks[i].checked = selectAll;
      productRows[i].style.backgroundColor = rowColor;
      productRows[i].style.color = textColor;
    }
    let filteredPlayers = [];
    if (selectAll) {
      players
        .filter(
          (player) =>
            player.masterOrderItemId === null || player.masterOrderItemId === ''
        )
        .map((player) => filteredPlayers.push(player));
      setPlayerSelectCount(playerLength);
      document.getElementById('selectAll').disabled = true;
      document.getElementById('unselectAll').disabled = false;
    } else {
      setPlayerSelectCount(0);
      document.getElementById('selectAll').disabled = false;
      document.getElementById('unselectAll').disabled = true;
    }
    setSelectedPlayers(filteredPlayers);
  }

  function sortLines(players) {
    players.sort((a, b) => {
      let w;
      let x;
      let y;
      let z;

      /* --Sort Student Name---*/

      // if (a.fingerSize === null || a.fingerSize === '') w = 0;
      // else w = a.fingerSize;
      // if (b.fingerSize === null || b.fingerSize === '') x = 0;
      // else x = b.fingerSize;
      if (a.studentName === null) y = '';
      else y = a.studentName.toLowerCase();
      if (b.studentName === null) z = '';
      else z = b.studentName.toLowerCase();
      if (w === x) {
        return y > z ? 1 : -1;
      }
      return w - x;
    });
    return players;
  }

  function sortProducts(products) {
    products.sort(function (a, b) {
      let x;
      let y;
      if (a.title) {
        x = a.title.toLowerCase();
      } else {
        x = '';
      }
      if (b.title) {
        y = b.title.toLowerCase();
      } else {
        y = '';
      }
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });
    return products;
  }

  function filterPlayers(players, product) {
    if (product) {
      return players.filter(
        (player) => player.masterOrderItemId === product.masterOrderItemId
      );
    }
    return players.filter((player) => player.masterOrderItemId === null);
  }

  function appendProducts(products) {
    const defaultProducts = [
      {
        masterOrderItemId: '1000000007',
        title: 'Ring Only Package',
        productType: 'Ring Only Package',
        variations: [{ description: '', artworkUrl: '' }],
        customizations: [],
      },
      {
        masterOrderItemId: '1000000008',
        title: 'Champions Package',
        productType: 'Champions Package',
        variations: [{ description: '', artworkUrl: '' }],
        customizations: [],
      },
      {
        masterOrderItemId: '1000000009',
        title: 'Dynasty Package',
        productType: 'Dynasty Package',
        variations: [{ description: '', artworkUrl: '' }],
        customizations: [],
      },
      {
        masterOrderItemId: '1000000010',
        title: 'Engraving',
        productType: 'Engraving',
        variations: [{ description: '', artworkUrl: '' }],
        customizations: [],
      },
    ];

    let sortedProducts = sortProducts(products);

    if (
      products.filter((product) => product.masterOrderItemId === '1000000007')
        .length === 0
    ) {
      sortedProducts.map((product) => defaultProducts.push(product));
      sortedProducts = defaultProducts;
    }

    return sortedProducts;
  }

  const [appendedProducts] = useState(appendProducts(products));

  const onDeleteClick = (playerData) => {
    deleteRosterLine(rosterKey, playerData.id);
    let newPlayers = sortedPlayers.filter((pl) => pl.id !== playerData.id);
    setSortedPlayers(sortLines(newPlayers));
  };

  const emptyProducts = sortedPlayers.filter(
    (player) => player.masterOrderItemId === null
  );

  const closeRosterCompleteModal = () => {
    setShowRosterCompleteModal(false);
  };
  const handleShowRosterCompleteModal = () => {
    setShowRosterCompleteModal(true);
  };

  const handleRosterComplete = async (roster) => {
    setCloseRosterLoading(true);
    await updateRosterCompleteFlag(roster);
    setCloseRosterLoading(false);
    setRosterComplete(true);
    closeRosterCompleteModal();
    document.getElementById('completeBtn').innerText = 'Client Guide Notified';
    document.getElementById('completeBtn').style.backgroundColor = '#5cb85c';
    showAllDoneVideoModal();
  };

  const downloadTeamRoster = async (rosterKey) => {
    setDownloadingRoster(true);
    await downloadRoster(rosterKey, 'TeamRosterFile');
    setDownloadingRoster(false);
  };

  const showAllDoneVideoModal = () => {
    setAllDoneVideoModal(true);
  };

  const hideAllDoneVideoModal = () => {
    setAllDoneVideoModal(false);
  };

  return (
    <Container>
      <Row>
        {rosterComplete === false && (
          <>
            <Col sm={4}>
              {sortedPlayers.length > 0 && (
                <Button
                  id="completeBtn"
                  onClick={() => handleShowRosterCompleteModal()}
                  variant="secondary"
                  className="mb-1"
                >
                  Complete Roster
                </Button>
              )}
            </Col>
          </>
        )}
        {rosterComplete === true && (
          <>
            <Col>
              <Button
                id="completeBtn"
                variant="success"
                className="mb-1"
                onClick={() => handleShowRosterCompleteModal()}
              >
                Roster Complete
              </Button>
            </Col>
          </>
        )}
        <Col className="text-right" sm={8}>
          {((roster.paymentType !== 'Individual Pay' &&
            rosterComplete === false) ||
            internalView) && (
            <Button onClick={() => enablePlayerAdd()} className="mb-1">
              <FontAwesomeIcon icon={faPlusCircle} /> Add Team Member's Order
            </Button>
          )}
          {rosterComplete === true && (
            <Button
              className="mb-1"
              onClick={() => downloadTeamRoster(rosterKey)}
            >
              {downloadingRoster === true ? (
                <Spinner animation="border" />
              ) : (
                'Download Roster'
              )}
            </Button>
          )}
        </Col>
      </Row>
      {emptyProducts.length !== 0 && (
        <Accordion className="accordianRow" defaultActiveKey="0">
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              <Card.Title>No Product Selected</Card.Title>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                {rosterComplete === false && (
                  <>
                    <Button
                      id="selectAll"
                      onClick={() => selectAllPlayers(sortedPlayers, true)}
                    >
                      Select All Players
                    </Button>{' '}
                    <Button
                      id="unselectAll"
                      onClick={() => selectAllPlayers(sortedPlayers, false)}
                    >
                      Un-Select All Players
                    </Button>
                  </>
                )}
                <PlayerCards
                  sortedPlayers={sortedPlayers}
                  product={''}
                  orderInfo={orderInfo}
                  editPlayer={(playerData) => enablePlayerEdit(playerData)}
                  editPlayers={(playersData) => enablePlayersEdit(playersData)}
                  deleteCard={(lineId) => onDeleteClick(lineId)}
                  rosterComplete={rosterComplete}
                  individualSelection={enableIndividualSelection}
                  multipleSelection={enableMultipleSelection}
                  selectedPlayers={selectedPlayers}
                  setSelectedPlayers={setSelectedPlayers}
                  playerSelectCount={playerSelectCount}
                  setPlayerSelectCount={setPlayerSelectCount}
                  incrementPlayerCount={incrementPlayerCount}
                  decrementPlayerCount={decrementPlayerCount}
                  internalView={internalView}
                />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      )}
      {appendedProducts.map(
        (product) =>
          (product.masterOrderItemId >= '1000000007' &&
            product.masterOrderItemId <= '1000000010' &&
            filterPlayers(sortLines(players), product).length > 0) ||
          ((product.masterOrderItemId < '1000000007' ||
            product.masterOrderItemId > '1000000010') && (
            <Accordion
              className="accordianRow"
              defaultActiveKey="0"
              key={product.masterOrderItemId}
              style={{ marginTop: '1rem' }}
            >
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="0">
                  <Card.Title>
                    {product.title} &mdash;{' '}
                    {filterPlayers(sortedPlayers, product).length}
                  </Card.Title>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <Row className="justify-content-end mr-1 mb-3">
                      {product.variations
                        .map((variation) => variation.artworkUrl)
                        .filter(
                          (value, index, self) => self.indexOf(value) === index
                        )
                        .map((artwork) => (
                          <Col key={artwork} sm={true}>
                            {artwork !==
                              'https://drive.google.com/uc?export=view&id=' && (
                              <Image
                                style={{ maxWidth: '300px' }}
                                fluid
                                src={artwork}
                              ></Image>
                            )}
                          </Col>
                        ))}
                      <Col xs={1}>
                        {((roster.paymentType !== 'Individual Pay' &&
                          rosterComplete === false) ||
                          internalView) && (
                          <Button onClick={() => enablePlayerAdd(product)}>
                            <FontAwesomeIcon icon={faPlusCircle} />
                          </Button>
                        )}
                      </Col>
                    </Row>
                    <PlayerCards
                      sortedPlayers={sortedPlayers}
                      product={product}
                      orderInfo={orderInfo}
                      editPlayer={(playerData) => enablePlayerEdit(playerData)}
                      deleteCard={(playerData) => onDeleteClick(playerData)}
                      rosterComplete={rosterComplete}
                      individualSelection={enableIndividualSelection}
                      multipleSelection={enableMultipleSelection}
                      internalView={internalView}
                      //selectedPlayers = {selectedPlayers}
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          ))
      )}
      <Modal id="mainModal" show={show} onHide={cancelEdit} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Team Member Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <RingSelectionView
            rosterKey={rosterKey}
            roster={roster}
            playerData={player}
            closeModal={handleClose}
            cancelEdit={cancelEdit}
            editCancelled={editCancelled}
            updatePlayer={(playerData) => updatePlayer(playerData)}
            addPlayer={(playerData) => addPlayer(playerData)}
            individualSelection={individualSelection}
            selectedPlayers={selectedPlayers}
            setSelectedPlayers={setSelectedPlayers}
            playerSelectCount={playerSelectCount}
            setPlayerSelectCount={setPlayerSelectCount}
            internalView={internalView}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={showRosterCompleteModal}
        onHide={closeRosterCompleteModal}
        animation={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {rosterComplete === false ? 'Are you sure?' : 'Roster Closed'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {rosterComplete === false ? (
            <div>
              <p>
                Confirm that all team members are on the roster and you are
                ready to move forward with your order.
              </p>

              <p>
                <strong>
                  Please note you will not be able edit or add orders once you
                  confirm.
                </strong>
              </p>

              <p>
                Your client guide will be notified that you are ready to move
                forward with the order!
              </p>
            </div>
          ) : (
            'If you need further changes, please ask your Client Guide to open this roster page for you.'
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeRosterCompleteModal}>
            Cancel
          </Button>
          {rosterComplete === false && (
            <Button
              disabled={closeRosterLoading}
              variant="success"
              onClick={() => {
                handleRosterComplete(roster);
              }}
            >
              {closeRosterLoading ? (
                <Spinner size="sm" animation="border" />
              ) : (
                'Confirm'
              )}
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      <Modal
        show={allDoneVideoModal}
        hide={hideAllDoneVideoModal}
        animation={false}
        centered
      >
        <Modal.Header
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Modal.Title centered>
            Thank You For Submitting Your Roster!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body centered style={{ justifyContent: 'center' }}>
          <div
            style={{
              display: 'flex',
              fontSize: '9pt',
              textAlign: 'center',
              alignItems: 'center',
            }}
          >
            We are working to put all of your order details together. You will
            receive an email soon to review and approve your order before it
            enters production.{' '}
          </div>
          {/* <br />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              fontWeight: 'bold',
            }}
          >
            Please watch this video for instructions on how to proceed.
            <br />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ReactPlayer
              url="https://f.hubspotusercontent20.net/hubfs/3305623/Online-Roster/All_Done.mp4"
              controls={true}
            />
          </div> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={() => hideAllDoneVideoModal()}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};
export default PlayerCard;
