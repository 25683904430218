import OrderPage from "./pages/OrderPage/OrderPage";
import "./App.css";
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { UserProvider } from './contexts/UserContext';

function App() {
  return (
    <UserProvider>
      <div className="App">
        <OrderPage />
      </div>
    </UserProvider>
  );
}

export default App;
