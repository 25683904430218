import './PackageComparisonTable.css';

const RingComparisonTable = () => {
  return (
    <>
      <div id="PackageConfirmationModal">
        <div className="first-column">
          <ul className="price">
            <li
              style={{ color: '#FFF', backgroundColor: '#FFF' }}
              className="header"
            >
              .
            </li>
            <li
              style={{ color: '#FFF', backgroundColor: '#FFF' }}
              className="most-popular"
            >
              .
            </li>
            <li
              style={{ color: '#FFF', backgroundColor: '#FFF' }}
              className="grey"
            >
              .<br />
              <span style={{ fontSize: '10px' }}>.</span>
            </li>
            <li className="long-table-row-titles">Warranty</li>
            <li className="long-table-row-titles">Inside Engraving</li>
            <li className="long-table-row-titles">Polishing Cloth</li>
            <li className="long-table-row-titles">Resizing For Life</li>
            <li className="long-table-row-titles">Ring Loss Insurance</li>
            <li className="table-row-titles">Custom T-shirt</li>
            <li className="long-table-row-titles">Drawstring Bag</li>
            <li className="long-table-row-titles">Jersey # Pendant</li>
            <li className="long-table-row-titles">Box Upgrade</li>
            <li className="grey"></li>
          </ul>
        </div>
        <div className="columns">
          <ul className="price">
            <li className="header">Champions</li>
            <li className="most-popular" style={{ color: 'black' }}>
              .
            </li>
            <li className="grey">
              + $20
              <br />
              <span style={{ color: '#B12928', fontSize: '10px' }}>
                SAVE $80
              </span>
            </li>
            <li style={{ paddingTop: '20px', fontWeight: '300' }}>Limited</li>
            <li className="checkmarks">&#10004;</li>
            <li className="checkmarks">&#10004;</li>
            <li className="checkmarks"></li>
            <li className="checkmarks"></li>
            <li className="checkmarks"></li>
            <li className="checkmarks"></li>
            <li className="checkmarks"></li>
            <li className="long-table-row-titles">Jumbo LED Box</li>
            <li className="grey"></li>
          </ul>
        </div>
        <div className="columns">
          <ul className="price">
            <li className="header">Champions+</li>
            <li className="most-popular" style={{ color: 'black' }}>
              .
            </li>
            <li className="grey">
              + $30
              <br />
              <span style={{ color: '#B12928', fontSize: '10px' }}>
                SAVE $95
              </span>
            </li>
            <li style={{ paddingTop: '20px', fontWeight: '300' }}>Limited</li>
            <li className="checkmarks">&#10004;</li>
            <li className="checkmarks">&#10004;</li>
            <li className="checkmarks"></li>
            <li className="checkmarks"></li>
            <li className="checkmarks"></li>
            <li className="checkmarks"></li>
            <li className="checkmarks"></li>
            <li className="long-table-row-titles">Nameplate LED Box</li>
            <li className="grey"></li>
          </ul>
        </div>
        <div className="columns">
          <ul className="price">
            <li className="header">Dynasty</li>
            <li className="most-popular">MOST POPULAR</li>
            <li className="grey">
              + $40
              <br />
              <span style={{ color: '#B12928', fontSize: '10px' }}>
                SAVE $165
              </span>
            </li>
            <li style={{ paddingTop: '20px' }}>Lifetime</li>
            <li className="checkmarks">&#10004;</li>
            <li className="checkmarks">&#10004;</li>
            <li className="checkmarks">&#10004;</li>
            <li className="checkmarks">&#10004;</li>
            <li className="checkmarks"></li>
            <li className="checkmarks"></li>
            <li className="checkmarks"></li>
            <li className="long-table-row-titles">Custom Nameplate Box</li>
            <li className="grey"></li>
          </ul>
        </div>
        <div className="columns">
          <ul className="price">
            <li className="header">Ultimate</li>
            <li className="most-popular">BEST VALUE</li>
            <li className="grey">
              + $99
              <br />
              <span style={{ color: '#B12928', fontSize: '10px' }}>
                SAVE $210
              </span>
            </li>
            <li style={{ paddingTop: '20px' }}>Lifetime</li>
            <li className="checkmarks">&#10004;</li>
            <li className="checkmarks">&#10004;</li>
            <li className="checkmarks">&#10004;</li>
            <li className="checkmarks">&#10004;</li>
            <li className="checkmarks">&#10004;</li>
            <li className="checkmarks">&#10004;</li>
            <li className="checkmarks">&#10004;</li>
            <li className="long-table-row-titles">Custom Nameplate Box</li>
            <li className="grey"></li>
          </ul>
        </div>
        <div className="half-columns">
          <ul className="price">
            <li className="header">Basic</li>
            <li className="table-row-titles">Limited Warranty</li>
            <li className="table-row-titles">Basic Box</li>
            <li className="grey"></li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default RingComparisonTable;
