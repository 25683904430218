import React, { useState, useEffect, useRef, useContext } from 'react';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import toCamelCase from '../../utils/toCamelCase';
import './InputGenerator.css';

const InputGenerator = ({
  label,
  options,
  maxLength,
  handleInputChange,
  inputValue,
}) => {
  const [value, setValue] = useState(inputValue);
  const [inputName, setInputName] = useState('');
  const [
    isInsideRingEngravingCustomization,
    setIsInsideRingEngravingCustomization,
  ] = useState(
    label.trim().toLowerCase() == 'inside ring engraving' ? true : false
  );
  const [showSpacebarMessage, setShowSpacebarMessage] = useState(
    label.trim().toLowerCase().includes('name on ring') ||
      label.trim().toLowerCase().includes('number')
      ? false
      : true
  );
  const [showTooltip, setShowTooltip] = useState(false);
  const inputRef = useRef(null);

  const handleChange = (event, inputName) => {
    setValue(event.target.value);
    setInputName(inputName);
    if(event.target.value.length >= maxLength){
      setShowTooltip(true);
      setTimeout(() => {
        setShowTooltip(false);
      }, 2000);
    }
  };

  useEffect(() => {
    handleInputChange(label, value);
  }, [value, label]);

  useEffect(() => {
    setValue(inputValue);
  }, [inputValue]);

  const renderTooltip = () => (
    <Tooltip id="tooltip" className="custom-tooltip"><span class="tooltip-icon">!</span>Character limit reached</Tooltip>
  );

  const handleInputClick = () => {
    setShowTooltip(true);
    if(value.length >= maxLength){
      setShowTooltip(true);
      setTimeout(() => {
        setShowTooltip(false);
      }, 2000);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setShowTooltip(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="mb-3">
      {options && options.length > 0 && (
        <>
          <Form.Label>{label}</Form.Label>
          <Form.Control as="select" custom>
            {options.map((product, idx) => (
              <option key={idx}>{product}</option>
            ))}
          </Form.Control>
        </>
      )}
      {maxLength != undefined ? (
        <>
          <Form.Label>
            {label}{' '}
            {showSpacebarMessage ? (
              <small className="text-muted">
                (Use spacebar to leave blank)
              </small>
            ) : (
              <></>
            )}
            {isInsideRingEngravingCustomization ? (
              <>
                <br></br>
                <small className="text-danger">
                  ($10 w/ Basic package or FREE w/ any package upgrade)
                </small>
              </>
            ) : (
              <></>
            )}
          </Form.Label>

          <OverlayTrigger
            trigger={["click"]}
            placement="bottom"
            overlay={renderTooltip()}
            show={showTooltip  && value.length >= maxLength}
          >
            <Form.Control
              required
              type="text"
              placeholder={`Max ${maxLength} Characters`}
              maxLength={maxLength}
              name={toCamelCase(label)}
              onChange={(event) => handleChange(event, toCamelCase(label))}
              defaultValue={value}
              style={{ textTransform: 'uppercase' }}
              ref={inputRef}
              onClick={handleInputClick}
            />
          </OverlayTrigger>
          <Form.Control.Feedback type="invalid">
            Please enter the customization value.
          </Form.Control.Feedback>
        </>
      ) : (
        <>
          <Form.Label>
            {label}{' '}
            {showSpacebarMessage ? (
              <small className="text-muted">
                (Use spacebar to leave blank)
              </small>
            ) : (
              <></>
            )}
            {isInsideRingEngravingCustomization ? (
              <>
                <br></br>
                <small className="text-danger">
                  ($10 w/ Basic package or FREE w/ any package upgrade)
                </small>
              </>
            ) : (
              <></>
            )}
          </Form.Label>

          <Form.Control
            required
            type="text"
            name={toCamelCase(label)}
            onChange={(event) => handleChange(event, toCamelCase(label))}
            defaultValue={value}
            style={{ textTransform: 'uppercase' }}
            ref={inputRef}
            onClick={handleInputClick}
          />
          <Form.Control.Feedback type="invalid">
            Please enter the customization value.
          </Form.Control.Feedback>
        </>
      )}
    </div>
  );
};

export default InputGenerator;
